<template>
  <div id="doctor-documents">
    <el-row>
      <el-col :span="4">
        <Folders />
      </el-col>
      <el-col class="content-2" :span="20">
        <Content />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Content from "../../components/filemanager/Content.vue";
import Folders from "../../components/filemanager/Folders.vue";

export default {
  components: { Folders, Content },
};
</script>

<style lang="less"></style>
